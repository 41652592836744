import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "Dmitry Birin Personal site"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`]}
        />
        <h1>
        <span role="img" aria-label="construction emoji">
            🚧
          </span>
          UNDER CONSTRUCTION 
          <span role="img" aria-label="construction emoji">
            🚧
          </span>
        </h1>
        <p>Welcome... but not yet. Trying to build something with Gatsby.</p>
        {/* <Link to="/blog/"> */}
          <Button marginTop="35px">This button don't do anything</Button>
        {/* </Link> */}
      </Layout>
    )
  }
}

export default IndexPage
